<template>
  <div>
    <nav class="navbar navbar-light bg-light p-2" style="z-index: 5">
      <a class="navbar-brand sidebar-navigation">
        <i class="fas fa-search p-1" @click="initializeSearch" v-b-tooltip.hover.right title="Search Organization"></i>
      </a>
      <div class="d-flex ml-auto align-items-center">
        <div class="p-1" v-if="showSponsor">SPONSOR LOGO</div>
        <div class="p-1">
          <a href="https://www.medicalalley.org/" target="_blank" v-b-tooltip.hover.left title="To MedicalAlley.org">
            <img src="https://s3.us-west-2.amazonaws.com/alleyinsights.org/img/header-logo-01.png">
          </a>
        </div>
      </div>
    </nav>
    <transition name="fade">
      <loading-screen v-if="isLoading" style="z-index: 999;"/>
    </transition>
    <div>
      <div id="map" class="map"></div>
      <div v-if="globalPresenceData !== null" class="sidebar" v-bind:class="{ active: sidebarOpen }">
        <div class="d-flex align-items-center p-2">
          <div class="d-flex flex-fill">
            <i class="fas fa-arrow-left sidebar-navigation" v-if="history.length > 1" v-on:click="goBackInHistory"></i>
          </div>
          <div class="ml-auto">
            <i class="fas fa-times sidebar-navigation" v-on:click="closeSideBar"></i>
          </div>
        </div>
        <div v-if="currentView.type === 'search'">
          <div class="p-3 d-flex align-items-center">
            <b-form-input class="p-2" v-model="currentView.id" v-on:keyup.enter="setOrganizationSearch(currentView.id)" placeholder="Enter organization name"></b-form-input>
            <div class="d-flex justify-content-center p-2">
              <i class="fas fa-search" style="cursor: pointer;" @click="setOrganizationSearch(currentView.id)" ></i>
            </div>
          </div>
          <div v-if="searchPerformed">
            <div class="text-center">
              {{ organizations.length }} {{ organizations.length === 1 ? 'result':'results' }}
            </div>
            <hr/>
            <div class="d-flex align-items-center company-row" v-for="company in organizations" :key="company.id" @click="setOrganizationData(company.id)">
              <div class="p-1">
                <img v-lazy="company.logo_url" class="img-fluid p-1" style="max-width: 35px">
              </div>
              <div class="p-1" style="font-size: 1.2em;" >
                {{ company.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentView.type === 'default'" class="p-2">
          <div class="text-center">
            <img style="max-width: 75%" class="img-fluid" src="https://medical-alley-association-data-interface.s3.us-west-2.amazonaws.com/uploads/globalinfluence2-02.png"/>
          </div>
          <div class="text-center" v-if="showSponsor">
            <div style="font-weight: 300; text-transform: uppercase; font-size: 0.8em;">Sponsored by</div>
            <div>SPONSOR LOGO</div>
          </div>
          <div class="mt-4">
            The organizations that comprise Medical Alley can be found throughout the world, spreading the influence of Minnesota’s health care prowess globally.
          </div>
          <div >
            <hr/>
            <div class="text-center pb-4" style="font-weight: 700">HOW TO USE</div>
            <div class="pb-4" v-for="slide in slides" :key="slide.id" style="font-size: 0.8em;">
              <div>
                <img class="img-fluid" :src="slide.image">
              </div>
              <div class="text-center pt-1">
                {{ slide.description }}
              </div>
            </div>
            <div class="pt-4 pb-4 text-center" style="font-weight: 300; font-size: 0.9em;">
              Data was collected from the official websites of the companies; 510(k), Premarket Approval, drug approval documents and manufacturer data from the FDA; CMS.gov hospital and supplier data; and information provided to the Medical Alley Association.
              <br/><br/>
              Erroneous or missing location? Please contact rdevries@medicalalley.org
            </div>
          </div>
        </div>
        <div v-if="currentView.type === 'location'">
          <div class="p-1">
            <div v-if="selectedLocation.line_one !== null" class="text-center" style="font-size: 2em;">
              {{ selectedLocation.line_one }}
            </div>
            <div class="text-center" style="font-size: 1.5em;">
              {{ selectedLocation.country }}
            </div>
          </div>
          <hr/>
          <div>
            <div class="d-flex align-items-center company-row" v-for="company in organizations" :key="company.id" @click="setOrganizationData(company.id)">
              <div class="p-1">
                <img v-lazy="company.logo_url" class="img-fluid p-1" style="max-width: 35px">
              </div>
              <div class="p-1" style="font-size: 1.2em;" >
                {{ company.name }}
              </div>
            </div>
            <div class="p-1 mt-5 text-center" style="font-weight: 300; font-size: 0.8em;">
              Click an organization to view their locations.
            </div>
          </div>
        </div>
        <div v-if="currentView.type === 'organization' && selectedOrganization !== null">
          <div class="">
            <div class="p-1 text-center">
              <img v-lazy="selectedOrganization.logo_url" class="img-fluid p-1" style="max-width: 100px">
            </div>
            <div class="p-1 text-center" style="font-size: 1.2em;">
              {{ selectedOrganization.name }}
            </div>
          </div>
          <hr/>
          <div>
            <div class="d-flex align-items-center company-row " style="font-size: 1.2em;" v-for="location in locations" :key="location.location_id" @click="setLocationData(location.location_id)">
              <div class="p-1">
                <i class="fas fa-map-marker-alt" style="color: #BE1E2D"></i>
              </div>
              <div class="p-1 ">
                <div>{{ location.line_one }}</div>
                <div style="font-weight: 300">{{ location.country }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container m-2 d-flex flex-column justify-content-center align-items-center">
        <div class="zoom-button d-flex align-items-center justify-content-center m-1 btn" @click="zoomIn" v-b-tooltip.hover.left title="Zoom In">
          <i class="fas fa-plus"></i>
        </div>
        <div class="zoom-button d-flex align-items-center justify-content-center m-1 btn" @click="zoomOut" v-b-tooltip.hover.left title="Zoom Out">
          <i class="fas fa-minus"></i>
        </div>
        <div class="instruction-button d-flex align-items-center justify-content-center m-1 btn" @click="initializeInstructions" v-b-tooltip.hover.left title="Show Instructions">
          <i class="fas fa-question"></i>
        </div>
        <div class="reset-button d-flex align-items-center justify-content-center m-1 btn" @click="setDefaultData" v-b-tooltip.hover.left title="Reset Map">
          <i class="fas fa-redo-alt"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../../api'
import LoadingScreen from '../LoadingScreen'
import L from 'mapbox.js'

export default {
  name: '',
  components: {LoadingScreen},
  computed: {
    organizations () {
      return this.globalPresenceData.organizations
    },
    locations () {
      return this.globalPresenceData.locations
    },
    selectedLocation () {
      return this.locations[0]
    },
    selectedOrganization () {
      if (this.isLoading) {
        return null
      }
      return this.organizations[0]
    }
  },
  data: function () {
    return {
      isLoading: false,
      sidebarOpen: false,
      showInstructions: false,
      markers: null,
      globalPresenceData: null,
      selectedID: null,
      currentView: null,
      searchPerformed: false,
      showSponsor: false,
      history: [],
      activeSlideIndex: 0,
      slides: [
        {
          id: 1,
          title: '',
          description: 'Select location dots to see which organizations have a presence there.',
          image:'https://s3.us-west-2.amazonaws.com/alleyinsights.org/img/instructable_location_search.png'
        },
        {
          id: 2,
          title: '',
          description: 'Use the search icon to look for locations of specific organizations.',
          image:'https://s3.us-west-2.amazonaws.com/alleyinsights.org/img/instructable_company_result.png'
        },
        {
          id: 3,
          title: '',
          description: 'Use this icon to refresh the map.',
          image:'https://s3.us-west-2.amazonaws.com/alleyinsights.org/img/instructable_refresh_data.png'
        },
        {
          id: 4,
          title: '',
          description: 'Use this button to show this dialog box again.',
          image:'https://s3.us-west-2.amazonaws.com/alleyinsights.org/img/instructable_launch_explainer.png'
        },
      ]
    }
  },
  methods: {
    initializeMap: function () {
      L.mapbox.accessToken = 'pk.eyJ1IjoiZGF2aWRoYXZlcmJlcmciLCJhIjoiY2lxMmswdmFpMDA2ZG5qbTQxdzBpNzBxcCJ9.DbkeZsIYslqK7BqDF62Lew'
      this.map = L.map('map', {zoomControl: false, center: [0, 0], zoom: 2})
      this.tileLayer = L.mapbox.styleLayer('mapbox://styles/davidhaverberg/cklgs0ne222kw17qvg8lf36wn')
      this.tileLayer.addTo(this.map)
    },
    initializeSearch () {
      this.currentView = {id: '', type: 'search'}
      this.sidebarOpen = true
      this.searchPerformed = false
      this.history = []
    },
    initializeInstructions () {
      this.currentView = {id: null, type: 'default'}
      this.sidebarOpen = true
    },
    setDefaultData () {
      this.isLoading = true
      this.sidebarOpen = false
      API.getPublicAPI('public/global_presence/').then(
        response => {
          this.isLoading = false
          this.history = []
          this.currentView = {id: null, type: 'default'}
          this.globalPresenceData = response.data
          this.setMarkers()
        },
        error => {
          this.isLoading = false
          console.log(error)
        }
      )
    },
    setOrganizationData (organizationID) {
      this.isLoading = true
      API.getPublicAPI('public/global_presence/organization/' + organizationID).then(
        response => {
          if (response.data.organizations.length > 0) {
            this.setSpecificData(organizationID, 'organization', response.data)
          }
          this.isLoading = false
        },
        error => {
          this.isLoading = false
          console.log(error)
        }
      )
    },
    setLocationData (locationID) {
      this.isLoading = true
      API.getPublicAPI('public/global_presence/location/' + locationID).then(
        response => {
          this.setSpecificData(locationID, 'location', response.data)
          this.map.flyTo([this.selectedLocation.latitude, this.selectedLocation.longitude])
          this.isLoading = false
        },
        error => {
          this.isLoading = false
          console.log(error)
        }
      )
    },
    setOrganizationSearch (searchTerm) {
      //  only search if more than one character is entered
      if (this.currentView.id.length < 2) {
        return
      }
      this.isLoading = true
      API.getPublicAPI('public/global_presence/search', { organization_name: searchTerm }).then(
        response => {
          this.setSpecificData(searchTerm, 'search', response.data)
          this.isLoading = false
          this.searchPerformed = true
        },
        error => {
          this.isLoading = false
          console.log(error)
        }
      )
    },
    setSpecificData (id, type, response) {
      this.history.push(this.currentView)
      this.currentView = {id: id, type: type}
      this.globalPresenceData = response
      this.highlightLocations()
      this.sidebarOpen = true
    },
    goBackInHistory () {
      let lastEntry = this.history.pop()
      if (lastEntry.type === 'organization') {
        this.setOrganizationData(lastEntry.id)
      } else if (lastEntry.type === 'location') {
        this.setLocationData(lastEntry.id)
      } else if (lastEntry.type === 'search') {
        this.setOrganizationSearch(lastEntry.id)
        this.history = []
      } else {
        this.setDefaultData()
      }
    },
    resetMarkers () {
      if (this.markers !== null) {
        this.map.removeLayer(this.markers)
      }
      this.markers = L.layerGroup()
      this.map.addLayer(this.markers)
    },
    setMarkers () {
      this.addLocations(this.globalPresenceData.locations)
    },
    addLocations (locations) {
      this.resetMarkers()
      let vm = this
      locations.forEach((location) => {
        if (location.latitude === 0 || location.longitude === 0) {
          return
        }
        vm.addLocation(location)
      })
    },
    highlightLocations () {
      let locationIDs = []
      this.globalPresenceData.locations.forEach((x) => { locationIDs.push(x.location_id) })
      this.markers.eachLayer((x) => {
        let isToBeHighlighted = locationIDs.indexOf(x.options.markerID) > -1
        x.setStyle({
          color: isToBeHighlighted ? '#c1272d' : '#555555'
        })
        if (isToBeHighlighted) {
          x.bringToFront()
        }
      })
    },
    addLocation (location) {
      let mapMarker = L.circleMarker(
        [location.latitude, location.longitude],
        {stroke: 0.25, color: '#c1272d', radius: 6, fillOpacity: 0.7, markerID: location.location_id, count: location.count}
      )
      let vm = this
      mapMarker.on('click', function () {
        vm.layerClick(location.location_id)
      })
      mapMarker.on('mouseover', function (e) {
        vm.layerHover(e)
      })
      mapMarker.on('mouseout', function (e) {
        vm.layerOut(e)
      })
      mapMarker.addTo(this.markers)
    },
    layerClick: function (locationID) {
      if (this.selectedLocation === null || this.selectedLocation.locationID !== locationID) {
        this.setLocationData(locationID)
        //  new selection
      } else {
        //  old selection
        this.closeSideBar()
      }
    },
    layerHover: function (e) {
      e.target.setRadius(15)
    },
    layerOut: function (e) {
      e.target.setRadius(6)
    },
    closeSideBar: function () {
      this.sidebarOpen = false
    },
    zoomIn () {
      this.map.setZoom(this.map.getZoom() + 1)
    },
    zoomOut () {
      this.map.setZoom(this.map.getZoom() - 1)
    }
  },
  mounted () {
    this.initializeMap()
    this.setDefaultData()
    if ('organization_id' in this.$route.query) {
      const passedOrganizationID = this.$route.query.organization_id;
      this.setOrganizationData(passedOrganizationID)
    } else {
      this.initializeInstructions()
    }


  }
}
</script>

<style scoped>
  body {
    margin:0;
    padding:0;
    background-color: black;
  }
  .map {
    position:absolute;
    top:0;
    bottom:0;
    width:100%;
    z-index: 1
  }
  .sidebar {
    height: 100%; /* 100% Full-height */
    width: 0;
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
    border-right: solid #BE1E2D 2px;
  }
  .instruction-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #BE1E2D;
    box-shadow: 2px 2px 5px #666666;
    color: #FFFFFF;
  }
  .reset-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #BE1E2D;
    box-shadow: 2px 2px 5px #666666;
    color: #FFFFFF;
  }
  .zoom-button{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #333;
    box-shadow: 2px 2px 5px #666666;
    color: #FFFFFF;
  }
  .button-container {
    position: fixed; /* Stay in place */
    z-index: 90; /* Stay on top */
    bottom: 0;
    right: 0;
  }
  .zoom-button:hover, .zoom-button:active{
    color: #FFFFFF;

  }

  @media only screen and (max-width: 600px) {
    .sidebar.active {
      width: 90%;
    }
    .instruction-button {
      width: 30px;
      height: 30px;
    }
    .reset-button {
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (min-width: 600px) {
    .sidebar.active {
      width: 33%;
    }
  }
  @media only screen and (min-width: 992px) {
    .sidebar.active {
      width: 25%;
    }
  }
  @media only screen and (min-width: 1280px) {
    .sidebar.active {
      width: 20%;
    }
  }
  .company-row {
    cursor: pointer;
  }
  .company-row:hover {
    background: #dddddd;
  }
  .sidebar-navigation {
    cursor: pointer;
  }
  .sidebar-navigation:hover {
    color: #BE1E2D;
  }
  div.leaflet-control-container > div.leaflet-top.leaflet-right {
    background: #BE1E2D;
  }
</style>
