<template>
    <div id="results" class="main-container">
      <div class="" v-if="ecosystemObjects !== null" >
        <div class="card" v-if="!hasMoreThanZeroResults">
          <div class="card-body text-center">
            NO RESULTS
          </div>
        </div>
        <div class="card-columns" v-if="hasMoreThanZeroResults">
          <single-object-in-list class="mb-2" v-for="ecosystemObject in objects" :key="ecosystemObject.id" v-bind:ecosystem-object="ecosystemObject" />
        </div>
        <p class="p-1 text-end">Contact Filip Kostal with questions:
          <a href="mailto: fkostal@medicalalley.org?subject=Resource%20Tool%20Inquiry&body=Feedback:" class="text-decoration-none">fkostal@medicalalley.org</a>
        </p>
      </div>
    </div>
</template>

<script>
import SingleObjectInList from './SingleObjectInList';
import EcosystemMixin from './EcosystemMixin';

export default {
  name: '',
  components: {SingleObjectInList},
  mixins: [EcosystemMixin],
  data: function () {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped>
    .main-container  {
      padding-left: 335px !important;
      background: #FCF7F7;
    }

</style>
