import store from '../../store'
import {mapGetters} from 'vuex';

export default {
  props: ['ecosystemObjects'],
  data: function () {
    return {
      searchQuery:{},
      tagTypes: [
        {text: 'Resource Type(s)', value: 'investor_tag'},
        // --Removed for relevance-- {text: 'Target Size(s)', value: 'investor_target_size'},
        {text: 'Focus Area(s)', value: 'investor_target_area'},
        {text: 'Availability', value: 'availability'}
      ]
    }
  },
  computed: {
    objects () {
      if (this.ecosystemObjects === null) {
        return
      }
      return this.ecosystemObjects.objects
    },
    tags () {
      return this.ecosystemObjects.tags
    },
    pagination () {
      return {
        currentPage: this.passedQuery.current_page,
        nextPage: this.passedQuery.next_page,
        totalResults: this.ecosystemObjects.total_results
      }
    },
    searchParameters () {
      return this.ecosystemObjects.parameters
    },
    passedQuery () {
      if (this.ecosystemObjects.passed_query === undefined) {
        return {}
      }
      return this.ecosystemObjects.passed_query
    },
    hasMoreThanZeroResults () {
      return this.objects.length > 0
    },
    // mix the getters into computed with object spread operator
    ...mapGetters(['query'])
  },
  methods: {
    formIsDirty () {
      store.commit('setFormStatus', true)
    },
    formIsClean () {
      store.commit('setFormStatus', false)
    },
    setEntireQuery (query) {
      store.commit('setFullQuery', query)
      this.formIsDirty()
    },
    addQueryParamsWithoutSearch(key, value) {
      store.commit('addQueryParam', [key, value])
      this.formIsDirty()
    },
    addTagParam(tagID) {
      if (store.getters.queriedTags.indexOf(tagID) > -1) {
        return
      }
      this.formIsDirty()
      store.commit('addTagParam', tagID)
    },
    removeTagParam(tagID) {
      let indexOfTag = store.getters.queriedTags.indexOf(tagID)
      if (indexOfTag === -1) {
        return
      }
      this.formIsDirty()
      store.commit('removeTagParam', indexOfTag)
    },
    isTagInQuery (tagID) {
      return store.getters.queriedTags.indexOf(tagID) > -1
    },
    resetQuery () {
      store.commit('resetESEQuery')
      //  searchQuery is used in components to have a model to work against.
      this.searchQuery = {}
      this.$emit('queryChanged')
    }
  }
}
