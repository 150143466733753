<template>
  <div class="home container-fluid d-flex align-items-center justify-content-center" style="height: 100vh; background: #55ABBF">
    <div>
      <img src="https://medicalalley.org/wp-content/uploads/2023/04/MAA_Logo_Outlined_Horizontal_White.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
