import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GlobalInfluence from '../components/globalInfluence/Index'
import NewGlobal from '../components/newGlobal/Index'
import EcosystemService from '../views/EcosystemServices'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/global-influence',
    name: 'Global Influence',
    meta: { title: 'Medical Alley\'s Global Influence' },
    component: GlobalInfluence
  },
  {
    path: '/new-global',
    name: 'New Global Influence',
    meta: { title: 'Medical Alley\'s Global Influence' },
    component: NewGlobal
  },
  {
    path: '/startup-resources',
    name: 'Ecosystem Service',
    meta: { title: 'Medical Alley\'s Ecosystem Service' },
    component: EcosystemService
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const DEFAULT_TITLE = 'Alley Insights';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router
