<template>
    <div v-if="ecosystemObjects !== null" class="pagination-bar">
      <div>
        <div class="d-flex align-items-center justify-content-center flex-fill">
          <!-- Back one page -->
          <button class="btn btn-pagination" :disabled="!hasPreviousPage" @click="setPreviousPage">
            <i class="fas fa-angle-left"></i>
          </button>
          <!-- Back to start -->
          <div class="d-flex" v-if="showFirstPage">
            <div class="px-2 page-number-holder">
            <div class="page-number text-center p-1" @click="setPageNumber(0)" style="cursor: pointer"> 1 </div>
            </div>
            <div class="page-number text-center p-1" style="cursor: default">  ...</div>
          </div>
          <!-- Presented Pages from Algo -->
          <div class="px-2 page-number-holder" v-for="pageNumber in nextPageNumbers" :key="pageNumber">
            <div
                class="page-number text-center p-1"
                :class="pageNumber === currentPageNumber ? 'current-page' : 'other-page'"
                @click="setPageNumber(pageNumber)"
            >
              {{ pageNumber}}
            </div>
          </div>

          <div class="d-flex" v-if="showLastPage">
            <div class="px-2 page-number-holder" v-if="showEllipsis">
              <div class="page-number text-center p-1" style="cursor: default">
                ...
              </div>
            </div>
            <div class="px-2 page-number-holder">
              <div class="page-number text-center p-1"
                style="cursor: pointer"
                @click="setPageNumber(totalPages)">
                {{ totalPages}}
              </div>
            </div>
          </div>
          <!-- Forward one page -->
          <button class="btn btn-pagination" :disabled="!hasNextPage" @click="setNextPage">
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
</template>

<script>
import EcosystemMixin from '@/components/ecosystemServices/EcosystemMixin';
// Pagination must only modify the current request.
export default {
  name: 'Pagination',
  mixins: [EcosystemMixin],
  data: function () {
    return {
    }
  },
  computed: {
    hasNextPage () {
      return this.currentPageNumber !== this.totalPages
    },
    hasPreviousPage () {
      return this.currentPageNumber !== 1
    },
    currentPageNumber () {
      return this.pagination.currentPage
    },
    totalResults () {
      return this.pagination.totalResults
    },
    totalPages () {
      return Math.ceil(this.totalResults / 10)
    },
    nextPageNumbers () {
      //  Set the full list/range list.
      let fullList = []
      for (let i = 1; i < this.totalPages + 1; i++) {
        fullList.push(i)
      }

      const startPage = this.currentPageNumber > 1 ? this.currentPageNumber : 2
      //  Set a stepped list
      let steppedList = []
      for (let i = startPage - 2; i < this.totalPages; i += 4) {
        steppedList.push(fullList[i])
      }
      if (steppedList.length > 1) {
        let listToReturn = []
        for (let i = steppedList[0]; i < steppedList[1] + 1; i++) {
          listToReturn.push(i)
        }
        return listToReturn
      }
      return fullList.slice(-5, fullList.length)
    },
    showLastPage () {
      //  don't show if there are fewer than five
      if (this.totalPages <= 5) {
        return false
      }

      return this.nextPageNumbers.indexOf(this.totalPages) === -1
    },
    showFirstPage (){
      if (!this.hasNextPage && this.totalPages > 5) {
        return true
      } else return false

    },
    showEllipsis () {
      if (this.currentPageNumber < this.totalPages - 4) {
        return true
      } else return false
    }
  },
  methods: {
    setNextPage () {
      this.passedQuery.current_page = this.currentPageNumber + 1
      this.setPaginatedPage()
    },
    setPreviousPage () {
      this.passedQuery.current_page = this.currentPageNumber - 1
      this.setPaginatedPage()
    },
    setPageNumber (pageNumber) {
      this.passedQuery.current_page = pageNumber
      this.setPaginatedPage()
    },
    setPaginatedPage () {
      this.setEntireQuery(this.passedQuery)
      this.$emit('queryChanged')
    }
  }
}
</script>

<style scoped>

.pagination-bar {
  padding-left: 335px !important;
}
.page-number-holder {
  width: 50px;

}
.page-number {
  font-weight: 700;
  width: 100%;
}
.other-page {
  cursor: pointer;
}
.current-page {
  background: #229ED9;
  color: #FFFFFF;
  border-radius: 50%;
}
.btn-pagination {
  border: solid 1px #dddddd;
}
</style>
