import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    eseQuery: {
      tags: []
    },
    isDirty: false
  },
  mutations: {
    setFormStatus (state, status) {
      state.isDirty = status
    },
    addQueryParam (state, query) {
      state.eseQuery[query[0]] = query[1]
    },
    tagParam (state,  query) {
      state.eseQuery.tags.push(query)
    },
    addTagParam (state, tagID) {
      state.eseQuery.tags.push(tagID)
    },
    removeTagParam (state, tagIndex) {
      state.eseQuery.tags.splice(tagIndex, 1)
    },
    resetESEQuery(state) {
      state.isDirty = false
      state.eseQuery = {tags: []}
    },
    setFullQuery(state, query) {
      state.eseQuery = query
    }
  },
  getters: {
    query (state) {
      return state.eseQuery
    },
    queriedTags (state) {
      return state.eseQuery.tags
    }
  }
})
