import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import VueLazyload from 'vue-lazyload'
import AOS from 'aos'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'aos/dist/aos.css'


import store from './store'

Vue.config.productionTip = false

Vue.use(AOS)
Vue.use(VueLazyload)
Vue.use(BootstrapVue)

new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    AOS.init()
  },
}).$mount('#app')
