<template>
    <div class="card card-block">
        <div class="card-body d-flex">
            <div class="p-1 d-flex align-items-start" v-if="ecosystemObject.logo_url !== null">
              <a class="company-title-link" v-if="ecosystemObject.url !== null" :href="ecosystemObject.url" target="_blank">
                <img class="img-fluid" :src="ecosystemObject.logo_url !== null ? ecosystemObject.logo_url : 'https://alley-insights-micro-sites.s3.us-west-2.amazonaws.com/img/sample_logo-01.png'" style="max-width: 50px">
              </a>
              <img v-else class="img-fluid" :src="ecosystemObject.logo_url !== null ? ecosystemObject.logo_url : 'https://alley-insights-micro-sites.s3.us-west-2.amazonaws.com/img/sample_logo-01.png'" style="max-width: 50px">
            </div>
            <div class="p-1 flex-fill">
                <div class="d-flex align-items-center">
                    <div class="object-title flex-fill">
                      <a class="company-title-link" v-if="ecosystemObject.url !== null" :href="ecosystemObject.url" target="_blank">
                        <b>{{ ecosystemObject.name }}</b>
                      </a>
                      <b v-else>{{ ecosystemObject.name }}</b>
                    </div>
                    <div class="ml-auto">
                        <a class="p-1" v-if="ecosystemObject.twitter_screen_name !== null" :href="ecosystemObject.twitter_screen_name" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a class="p-1" v-if="ecosystemObject.linkedin_url !== null" :href="ecosystemObject.linkedin_url" target="_blank">
                            <i class="fab fa-linkedin p-1"></i>
                        </a>
                        <a class="p-1" v-if="ecosystemObject.url !== null" :href="ecosystemObject.url" target="_blank">
                            <i class="fas fa-link"></i>
                        </a>
                    </div>
                </div>
                <div>
                    {{ location }}
                </div>
              <div>
                <div class="d-flex align-items-center flex-wrap my-1" v-for="tagType in organizedTags" :key="tagType.value" >
                  {{ tagType.text }}:&nbsp;
                  <div class="tag p-1 m-1" :class="isTagInQuery(tag.id) ? 'tag-in-query' : 'tag-badge'" v-for="tag in tagType.tags" :key="tag.id">
                    {{ tag.name }}
                  </div>
                </div>
              </div>
                <div class="object-description" v-if="!learnMoreToggled">
                    {{ learnMoreToggled? ecosystemObject.description : shortenedDescription }}
                    &nbsp;
                  <span v-if="!learnMoreToggled" class="learn-more" @click="learnMoreToggled = !learnMoreToggled">
                    LEARN MORE
                  </span>
                </div>
                <div v-if="learnMoreToggled">
                  <div class="object-description">
                    {{ ecosystemObject.description }}
                  </div>
                  <div class="text-center">
                    <span  class="learn-more" @click="learnMoreToggled = !learnMoreToggled">
                      HIDE
                    </span>
                  </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import EcosystemMixin from '@/components/ecosystemServices/EcosystemMixin';

export default {
  name: '',
  props: ['ecosystemObject'],
  mixins: [EcosystemMixin],
  data: function () {
    return {
      learnMoreToggled: false
    }
  },
  computed: {
    organizedTags () {
      let tagsToReturn = []

      for (let i = 0; i < this.tagTypes.length; i++) {
        const tagType = this.tagTypes[i]

        let applicableTags = this.ecosystemObject.tags.filter((x) => x.type === tagType.value)
        if (applicableTags.length === 0) {
          continue
        }
        tagType['tags'] = applicableTags
        tagsToReturn.push(tagType)
      }
      return tagsToReturn
    },
    location () {
      if (this.ecosystemObject.city !== null && this.ecosystemObject.state !== null && this.ecosystemObject.country !== null) {
        return this.ecosystemObject.city + ', ' + this.ecosystemObject.state + ', ' + this.ecosystemObject.country
      }
      if (this.ecosystemObject.state !== null && this.ecosystemObject.country !== null) {
        return this.ecosystemObject.state + ', ' + this.ecosystemObject.country
      }
      if (this.ecosystemObject.country !== null) {
        return this.ecosystemObject.country
      }
      return ''
    },
    shortenedDescription () {
      const maxWordCount = 100

      if (this.ecosystemObject.description.length < maxWordCount) {
        return this.ecosystemObject.description
      }
      let shortenedDescription = this.ecosystemObject.description.slice(0, maxWordCount)
      //  To make things pretty, look at the last position of a space.
      const lastSpace = shortenedDescription.lastIndexOf(' ')

      //   If last character is a period, comma, question mark or exclamation mark, omit that.
      shortenedDescription = shortenedDescription.slice(0, lastSpace)
      const lastCharacterOfShortenedDescription = shortenedDescription.slice(shortenedDescription.length-1)
      if (['.',',','?','!'].indexOf(lastCharacterOfShortenedDescription) > -1) {
        shortenedDescription = shortenedDescription.slice(0, shortenedDescription.length - 1)
      }

      return shortenedDescription + '...'
    }
  },
  methods: {
      getCleanedType (type) {
        const cleanedMap = {
          'investor_target_area': 'FOCUS',
          'investor_tag': 'TYPE',
          'availability': 'AVAILABILITY'
          // --Removed for relevance-- 'investor_target_size': 'TARGET SIZE'
        }
        if (type in cleanedMap) {
          return cleanedMap[type]
        }
        return type
      }
  }
}
</script>

<style scoped>
.company-title-link {
  color: #000000;
}
.card {
    border-radius: 0;
}
.object-title {
    font-size: 1.4em;
}
.object-description{
    font-weight: 300;
}
.learn-more {
  font-weight: 700;
  cursor: pointer;
  color: #008BCD;
}
.learn-more:hover {
  text-decoration: underline;
}

.tag {
  cursor: default;
}

</style>
