<template>
    <b-sidebar id="sidebar-no-header" title="Search" visible no-header shadow>
  <div v-if="ecosystemObjects !== null">
      <div class="pt-5 px-3 py-2">
        <div class="p-3">
          Organization Search
          <b-form-input
              v-model="searchQuery.organization_name"
              class="p-2"
              placeholder="Enter organization name"
              @change="(e) => addQueryParamsWithoutSearch('organization_name', searchQuery.organization_name)"
              v-on:keyup.enter="search"
          ></b-form-input>
        </div>
      </div>

      <div class="px-3 py-2 resource-group" v-for="resourceTagGroup in resourceTagGroups" :key="resourceTagGroup.text">
        <div class="px-3">
          {{ resourceTagGroup.text }}
        </div>
        <div class="d-flex flex-wrap flex-fill">
          <div class="tag p-1 pl-5 m-1"
                v-for="tag in resourceTagGroup.tags"
                :key="tag.value"
                :class="isTagInQuery(tag.value) ? 'tag-in-query' : 'tag-badge'"
                @click="isTagInQuery(tag.value) ? removeTagParam(tag.value) : addTagParam(tag.value); search()"
          >
            {{ tag.text }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-sm btn-search mt-2 mx-3" @click="search">SEARCH</button>
        <button class="btn btn-sm btn-reset mt-2 mx-3" @click="resetQuery">RESET</button>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import EcosystemMixin from './EcosystemMixin';

export default {
  name: '',
  mixins: [EcosystemMixin],
  computed: {
    resourceTypes () {
      let focuses = []
      this.searchParameters.filter((x) => x.type === 'investor_tag').forEach((x) => {
        focuses.push({text: x.name, value: x.id})
      })
      return focuses
    },
    // --removed for relevance-- resourceTargetSize () {
    //   let focuses = []

    //   this.searchParameters.filter((x) => x.type === 'investor_target_size').forEach((x) => {
    //     focuses.push({text: x.name, value: x.id})
    //   })
    //   return focuses
    // },
    resourceAvailability () {
      let focuses = []
      this.searchParameters.filter((x) => x.type === 'availability').forEach((x) => {
        focuses.push({text: x.name, value: x.id})
      })
      return focuses
    },
    resourceTargetArea () {
      let focuses = []
      this.searchParameters.filter((x) => x.type === 'investor_target_area').forEach((x) => {
        focuses.push({text: x.name, value: x.id})
      })
      return focuses
    },
    resourceTagGroups () {
      return [
        {
          text: 'Resource Type',
          tags: this.resourceTypes,
          buttonStyle: 'tag-badge'
        },
        // --removed for relevance-- {
        //   text: 'Target Size',
        //   tags: this.resourceTargetSize,
        //   buttonStyle: 'tag-badge'
        // },
        {
          text: 'Focus Areas',
          tags: this.resourceTargetArea,
          buttonStyle: 'tag-badge'
        },
        {
          text: 'Availability',
          tags: this.resourceAvailability,
          buttonStyle: 'tag-badge'
        }
      ]
    }
  },
  methods: {
    search () {
      this.$emit('queryChanged')

    },
  }
}
</script>

<style scoped>

  
  .resource-group {
    border: solid 1px #dddddd;
  }
  .btn-search {
    background: #229ED9;
    color: #FFFFFF;
    font-weight: 700;
    border: none;
  }
  .btn-reset {
    background: #229ED9;
    color: #FFFFFF;
    font-weight: 700;
    border: none;
  }


</style>
