import axios from 'axios'

export default {
  getPublicAPI (endpoint, params) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_ROOT_API + '/' + endpoint, {params: params})
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
