<template>
        <div v-if="ecosystemObjects !== null && hasQueryData" class="card">
          <div class="card-body">
            <div class="d-flex card-body align-items-center" v-if="tagsInQuery.length > 0 || stringsInQuery.length > 0">
              <div class="pr-5">QUERY:</div>
              <div class="tag tag-in-query p-1 pl-5 m-1"
                   v-for="tag in tagsInQuery"
                   :key="tag.id"
                   @click="removeTagParam(tag.id)"
              >
                {{ tag.name }}
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import EcosystemMixin from './EcosystemMixin';
export default {
  name: '',
  computed: {
    stringsInQuery ()  {
      let vm = this
      const potentialValues = ['organization_name']
      let toReturn = []
      potentialValues.forEach((x) => {
        if (x in vm.query) {
          let value = vm.query[x]
          if (value !== null && value !== '') {
            toReturn.push({
              key:x, value: value
            })
          }
        }
      })
      return toReturn
    },
    tagsInQuery () {
      if (!('tags' in this.passedQuery)) {
        return []
      }
      return this.searchParameters.filter((x)  => this.passedQuery['tags'].indexOf(String(x.id)) > -1)
    },
    hasQueryData () {
      return this.stringsInQuery.length > 0 || this.tagsInQuery.length > 0
    }
  },
  mixins: [EcosystemMixin]
}
</script>

<style scoped>
.remove-tag-button {
    border-left: solid 1px #62ACDD;
    font-size: 0.8em;
    cursor: pointer;
}
.reset-query {
    background: #BE1E2D;
}

</style>
