<template>
  <div class="d-flex justify-content-center align-items-center loading-container" @wheel.prevent @touchmove.prevent @scroll.prevent>
    <div class="text-center">
      <svg class="logo-container" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.61 84.56" style="width: 50px">
        <g>
          <polygon class="cls-1" points="0 0 0 69.43 14.44 84.56 14.44 69.43 35.22 69.43 35.22 55.17 14.68 55.17 14.68 15 54.37 15 54.37 55.17 40.76 69.43 68.61 69.43 68.61 0 0 0"/>
          <polygon points="39.61 22.89 34.53 31.5 29.44 22.89 22.9 22.89 22.9 47.28 29.45 47.28 29.45 34.93 34.01 42.21 35.17 42.21 39.66 35.03 39.66 47.28 46.16 47.28 46.16 22.89 39.61 22.89"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style scoped>
  .loading-container {
    position: absolute;
    height: 100%;
    background: rgba(255,255,255,0.5);
    z-index: 999;
    top:0;
    bottom:0;
    width:100%;
  }
  .cls-1 {
    fill: #c1272d;
  }
  .logo-container {
    -webkit-animation: pulsate 2s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0;
    z-index: 999;
  }

  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(1.0, 1.0); opacity: 1.0;
    }
    50% {
      -webkit-transform: scale(0.75, 0.75); opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.0, 1.0); opacity: 1.0;
    }
  }
</style>
