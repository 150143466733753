<template>
    <nav class="navbar navbar-dark navigation-container sticky-top p-2">
        <div>
            <img class="img-fluid" style="max-height: 35px;" src="https://medicalalley.org/wp-content/uploads/2023/04/MAA_Logo_Outlined_Mark_White.png">
        </div>
        <div class="ml-auto">
            SPONSOR LOGO
        </div>
    </nav>
</template>

<script>
import EcosystemMixin from './EcosystemMixin';
export default {
  name: '',
  mixins: [EcosystemMixin]
}
</script>

<style scoped>
    .navigation-container {
        background: rgba(11, 139, 204, 0.5);
        color: #FFFFFF;
        z-index: 9999;
    }
</style>
