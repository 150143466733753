<template>
    <ecosystem-service/>
</template>

<script>
import EcosystemService from '../components/ecosystemServices/Index';
export default {
  name: '',
  components: {EcosystemService}
}
</script>

<style scoped>

</style>
