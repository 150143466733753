<template>
    <div style="background: #FCF7F7; font-family: 'Montserrat', sans-serif;">
        <ecosystem-loading-screen v-if="isLoading"/>
        <navigation/>
        <div>
          <search v-on:queryChanged="queryChanged" v-bind:ecosystem-objects="ecosystemObjects" class="p-3"/>
          <m-a-s-header/>
          <query-listing v-if="false" v-on:queryChanged="queryChanged" v-bind:ecosystem-objects="ecosystemObjects" class="p-3"/>
          <main-container v-on:queryChanged="queryChanged" v-bind:ecosystem-objects="ecosystemObjects" class="p-3" />
          <pagination v-on:queryChanged="queryChanged" v-bind:ecosystem-objects="ecosystemObjects" class="p-3"/>
        </div>
        <cookie-law>
          <div class="d-flex flex-fill align-items-center" slot-scope="props">
            <div class="d-flex flex-fill">
              This website uses cookies to ensure you get the best experience on our website.
            </div>
            <div class="ml-auto">
              <button class="btn btn-accept" @click="props.accept"><span>I accept</span></button>
            </div>
          </div>
        </cookie-law>
    </div>
</template>

<script>

import Search from './Search';
import QueryListing from '@/components/ecosystemServices/QueryListing';
import MainContainer from './MainContainer';
import API from '../../api';
import { mapGetters } from 'vuex'
import CookieLaw from 'vue-cookie-law'
import MASHeader from '@/components/ecosystemServices/Header';
import Pagination from '@/components/ecosystemServices/Pagination';
import EcosystemLoadingScreen from '@/components/ecosystemServices/EcosystemLoadingScreen';
import store from '@/store';
import Navigation from '@/components/ecosystemServices/Navigation';

export default {
  name: '',
  components: {MainContainer, Search, QueryListing, CookieLaw, MASHeader, Pagination, EcosystemLoadingScreen, Navigation},
  data: function () {
    return {
      ecosystemObjects: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['query'])
  },
  methods: {
    setEcosystemObjects () {
      this.isLoading = true
      API.getPublicAPI('public/startup-resources/', this.query).then(
        response => {
          document.getElementById('results').scrollIntoView()
          this.ecosystemObjects = response.data
          this.setReceivedQuery(this.ecosystemObjects.passed_query)
          store.commit('setFormStatus', false)
          this.isLoading = false
        }
      )
    },
    setReceivedQuery(query) {
      //  Handles the response from the server.
      if (!('tags' in query)) {
        query['tags'] = []
      }
      store.commit('setFullQuery', query)
    },
    queryChanged () {
      this.setEcosystemObjects()
    },
    resetQuery () {
      this.setEcosystemObjects()
    }
  },
  mounted () {
    this.setEcosystemObjects()
  }
}
</script>

<style scoped>
    .introduction {
      font-weight: 700;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    .introduction-title {
      font-size: 4em;
      line-height: 1em;
    }
    .introduction-subtitle {
      font-size: 2em;
      line-height: 0.2em;
    }
    .introduction-detail {
      font-weight: 300;
      color: #FFFFFF;
    }
    .btn-accept {
      background: #89C4F4;
    }
</style>
